import * as React from 'react';

import Button from '../../ui/Buttons/Button';
import ImpressionTracker from '../../analytics/impression-tracker/ImpressionTracker';
import {Alerts} from '../../ui/Alerts/Alerts';
import {DisplayCurrencyFormatted} from '../display-currency-formatted/DisplayCurrencyFormatted';
import {GaItemListName} from '../../../client/ga/ga-ecommerce.functions';
import {Item} from '../../items/item.class';
import {SavedForLaterItem} from '../order.types';

interface SavedForLaterItemCardProps {
    canViewPrice: boolean;
    onAddSavedForLaterItemToOrder: (item: string, qty: number) => void;
    onDeleteSavedForLaterItem: (item: string) => void;
    orderNbr: string;
    position: number;
    savedForLaterItem: SavedForLaterItem;
}

export const savedForLaterGaItemListName: GaItemListName = `saved_for_later`;

export const SavedForLaterItemCard = ({
    canViewPrice,
    onAddSavedForLaterItemToOrder,
    onDeleteSavedForLaterItem,
    orderNbr,
    position,
    savedForLaterItem,
}: SavedForLaterItemCardProps) => {
    /**
     * Template
     */
    return (
        <div
            className="tw-relative tw-flex tw-flex-col tw-min-w-0 tw-border tw-bg-white tw-rounded tw-w-full tw-mb-4"
            key={savedForLaterItem.item}
        >
            <div className="tw-p-4">
                {!savedForLaterItem.inStock && (
                    <Alerts
                        message="This item is out of stock."
                        variant="warning"
                    />
                )}
                <div className="tw-flex">
                    <div className="tw-hidden md:tw-flex md:tw-flex-col">
                        <ImpressionTracker
                            data={{
                                dimension16: orderNbr,
                                index: position,
                                item_id: savedForLaterItem.item,
                                item_list_name: savedForLaterGaItemListName,
                            }}
                        >
                            <a
                                href={`/item/${savedForLaterItem.item}`}
                                className="tw-pl-1 tw-pr-12 tw-block"
                            >
                                <img
                                    alt={`${savedForLaterItem.desc}`}
                                    height="120"
                                    width="120"
                                    src={`/ProductImageThumbs120${savedForLaterItem.imagePath || '/noimage.png'}`}
                                />
                            </a>
                        </ImpressionTracker>
                    </div>
                    <div className="tw-flex-grow tw-flex tw-flex-col">
                        <div className="tw-flex tw-flex-row">
                            <div className="tw-flex-grow">
                                <ImpressionTracker
                                    data={{
                                        dimension16: orderNbr,
                                        index: position,
                                        item_id: savedForLaterItem.item,
                                        item_list_name: savedForLaterGaItemListName,
                                    }}
                                    trackImpressions={false}
                                >
                                    <a
                                        className="tw-text-gray-650"
                                        href={`/item/${savedForLaterItem.item}`}
                                    >
                                        #{Item.prettyItem(savedForLaterItem.item)}
                                    </a>
                                </ImpressionTracker>
                                <ImpressionTracker
                                    data={{
                                        dimension16: orderNbr,
                                        index: position,
                                        item_id: savedForLaterItem.item,
                                        item_list_name: savedForLaterGaItemListName,
                                    }}
                                    trackImpressions={false}
                                >
                                    <a
                                        className="tw-text-gray-650"
                                        href={`/item/${savedForLaterItem.item}`}
                                    >
                                        <div
                                            className="tw-text-xl tw-font-bold tw-mb-4 tw-hidden md:tw-block"
                                            dangerouslySetInnerHTML={{
                                                __html: savedForLaterItem.desc,
                                            }}
                                            style={{
                                                lineHeight: 1.5,
                                            }}
                                        ></div>
                                        <div
                                            className="tw-block md:tw-hidden tw-mb-4"
                                            dangerouslySetInnerHTML={{
                                                __html: savedForLaterItem.desc,
                                            }}
                                        ></div>
                                    </a>
                                </ImpressionTracker>
                            </div>
                        </div>
                        <div className="tw-flex tw-flex-row">
                            <div className="tw-flex-grow">
                                <ImpressionTracker
                                    data={{
                                        dimension16: orderNbr,
                                        index: position,
                                        item_id: savedForLaterItem.item,
                                        item_list_name: savedForLaterGaItemListName,
                                    }}
                                    trackImpressions={false}
                                >
                                    <a
                                        href={`/item/${savedForLaterItem.item}`}
                                        className="tw-pl-1 tw-pr-6 tw-my-4 tw-block md:tw-hidden"
                                    >
                                        <img
                                            alt={`${savedForLaterItem.desc}`}
                                            height="120"
                                            width="120"
                                            src={`/ProductImageThumbs120${savedForLaterItem.imagePath || '/noimage.png'}`}
                                        />
                                    </a>
                                </ImpressionTracker>
                            </div>
                            <div className="tw-flex-col">
                                <div className="tw-text-right">
                                    Pkg Qty: {savedForLaterItem.pkgQty}
                                    <div className="md:tw-hidden tw-mb-2" />
                                    <span className="tw-hidden md:tw-inline"> | </span>
                                    Order Qty: {savedForLaterItem.qty}
                                </div>
                                {canViewPrice && savedForLaterItem.amount && (
                                    <div className="tw-text-xl tw-font-bold tw-text-right tw-mt-2 tw-mb-0">
                                        <DisplayCurrencyFormatted amount={savedForLaterItem.amount} />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="tw-flex tw-flex-col-reverse md:tw-flex-row tw-mt-4">
                            <div className="tw-flex-grow tw-grid tw-gap-5 tw-grid-cols-2 md:tw-grid-cols-12 xl:tw-grid-cols-4">
                                <div className="tw-w-full tw-relative tw-col-span-1 md:tw-col-span-5 xl:tw-col-span-1">
                                    <Button
                                        className="tw-w-full"
                                        e2e="addToOrderBtnSaveForLater"
                                        onClick={() => {
                                            onAddSavedForLaterItemToOrder(savedForLaterItem.item, savedForLaterItem.qty);
                                        }}
                                        size="sm"
                                        variant="primary"
                                    >
                                        Add to Order
                                    </Button>
                                </div>
                                <div className="tw-w-full tw-relative tw-col-span-1 md:tw-col-span-5 xl:tw-col-span-1">
                                    <Button
                                        aria-label="Delete Item"
                                        className="tw-w-full"
                                        e2e="removeBtnSaveForLater"
                                        onClick={() => {
                                            onDeleteSavedForLaterItem(savedForLaterItem.item);
                                        }}
                                        size="sm"
                                        variant="outline-secondary"
                                    >
                                        Remove
                                    </Button>
                                </div>
                            </div>
                            <div className="tw-text-base tw-font-normal tw-text-right tw-mb-4 md:tw-mb-0 tw-px-8" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
